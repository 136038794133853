import React from "react"

import HeaderClients from "../../components/header_clients"
import ProfessionalEmployeePic from "../../static/images/ProfessionalEmployee.jpg"

export default function BusinessOwner() {
  const heroTitle = "Professional"

  return (
    <HeaderClients heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <figure class="image-block-wrapper mb-4">
        <img src={ProfessionalEmployeePic} alt={heroTitle} class="img-fluid img-thumbnail" />
      </figure>

      <p>
        Having worked as a corporate lawyer and being privy to the life of most set of
        professionals, the life and career of a professional is busy and demanding. It is typical to
        have long working hours during the week and even working weekends. Juggling between
        professional life and personal life often leaves professionals with very little time, energy
        and motivation to focus on managing personal finances. “Retail therapy” is where a fair bit
        of income goes on the pretext of making one feel better.
      </p>

      <p>
        It is often the case that despite earning high incomes / salaries, the asset / networth base
        does not seem to grow as a fair bit of control is lost over savings, investments and
        expenses. We assist our professional clients to achieve clarity and certainty, remove the
        complexity and put you in control of your financial life. As your personal financial
        officer, we ease the pressure on you and free you up to concentrate on what you do best ie.
        focus on progressing in your career and building up your wealth. Prudent financial planning
        is essential to make sure that you make the right financial decisions.
      </p>
    </HeaderClients>
  )
}
